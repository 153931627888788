.custom-tooltip {
  max-width: 500px; /* Asegura un ancho máximo */
  min-width: 300px; /* Asegura un ancho mínimo */
  width: auto; /* Permite ajuste automático del ancho */
  white-space: normal; /* Permite dividir texto en varias líneas */
  font-size: 14px; /* Ajusta el tamaño de la fuente */
  background-color: #333; /* Fondo oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px; /* Espaciado interno */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
  text-align: left !important; /* Fuerza el alineado a la izquierda */
}

.tooltip-inner {
  max-width: 500px !important; /* Fuerza el ancho máximo */
  min-width: 300px !important; /* Fuerza el ancho mínimo */
  white-space: normal; /* Permite texto en varias líneas */
  text-align: left !important; /* Fuerza el alineado a la izquierda */
}

.custom-tooltip div {
  margin-bottom: 5px; /* Espaciado entre líneas */
}

.custom-tooltip strong {
  font-weight: bold; /* Texto en negrita */
  color: #f9a825; /* Color amarillo para etiquetas */
}

.custom-tooltip span {
  font-weight: normal; /* Texto normal para valores */
  color: #fff; /* Texto blanco */
}

.table-container {
  overflow-x: auto;
  min-width: 400px;
}

/* (menos de 768px) */
@media (max-width: 767px) {
  .table-container .card-body {
    padding: 0;
  }
  .table-sm td, .table-sm th {
    padding: 20px 0 !important;
  }
}
