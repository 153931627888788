.custom-modal-content {
  width: 50% !important;
  height: 70% !important;
  margin: auto !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  overflow-y: auto !important; 
}

.checklist-modal-content {
  width: 50% !important;
  height: 70% !important;
  margin: auto !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  overflow-y: auto !important; 
}

/* Aplica estilos personalizados a los radio buttons */
.custom-radio-answer .custom-control-label {
  font-family: 'Arial', sans-serif !important;
  font-size:16px !important;                   
}

.common-font {
  font-family: 'Arial', sans-serif !important;
  font-size:16px !important;
}

.modal .modal-content .form-group {
  margin-bottom: 0rem !important;
}