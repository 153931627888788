.headerbreakspaces {
    background-color: #c8e6c9;
}

.table-dashboard-th-nowrap {
    background-color: #c8e6c9;
}

/* (menos de 768px) */
@media (max-width: 767px) {
    #actionrow {
        margin-right: 0;
    }
    #spec_searchbar_local {
        margin-left: 5px!important;
    }
    
  }
  